import React from 'react';
import './Footer.css';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Footer = () => {
  const { t } = useTranslation('footer');

  return (
    <>
      <div className="footer">
        <div className="logos">
          <img src={"/logo/logo.png"} className="logoimg" alt="logoimg" />
          <div className="open-text">
            <p>{t("weekdays")}:&nbsp;&nbsp;&nbsp;&nbsp;{t("days-time")}</p>
            <p>{t("weekends")}:&nbsp;&nbsp;&nbsp;&nbsp;{t("ends-time")}</p>
          </div>

          <div className='footer-break-line'></div>

          <div className="social">
            <p style={{ marginRight: "10px" }}>{t("social")}</p>
            <a href='https://www.facebook.com/people/Shree-Narayangiri-Agro-Vaijapur/61565737386134/' target="_blank" rel="noopener noreferrer"><img src={"/images/fb.png"} className="soc-icon" alt="soc-icon" /></a>
            <a href='https://www.instagram.com/shreenarayangiri_agro?igsh=MWg4eXVsam8zdnR2dQ==' target="_blank" rel="noopener noreferrer"><img src={"/images/inst2.png"} className="soc-icon" alt="soc-icon" /></a>
            <a href='https://youtube.com/@sanjaygagre1940?si=hVo9azEQirIW_-Sv' target="_blank" rel="noopener noreferrer"><img src={"/images/youtube.png"} className="soc-icon" alt="soc-icon" /></a>
            <a href='https://wa.me/9552581650' target="_blank" rel="noopener noreferrer"><img src={"/images/whatsapp.png"} className="soc-icon" alt="soc-icon" /></a>
          </div>
        </div>

        <div className="contacts">
          <h2>{t("footer-contacts")}</h2>
          <div className='contacts-content'>
            <img src={"/images/map.png"} alt="soc-icon" />
            <p>
              {t("address")}
            </p>
          </div>
          <div className='contacts-content'>
            <img src={"/images/phone-call.png"} alt="soc-icon" />
            <p>{t("phone")}</p></div>
          <div className='contacts-content'>
            <img src={"/images/mail.png"} alt="soc-icon" />
            <p>{t("email")}</p></div>
        </div>

        <div className="quicklinks">
          <h2>{t("quick-links")}</h2>
          <ul>
            <li><Link className="footer-links" to="/">{t("home")}</Link></li>
            <li><Link className="footer-links" to="/about">{t('about')}</Link></li>
            <li><Link className="footer-links" to="/products">{t('products')}</Link></li>
            <li><Link className="footer-links" to="/gallery">{t('gallery')}</Link></li>
          </ul>
        </div>
      </div>
      <div className="copyright">©2024 Group AgroSphere. All rights reserved.</div>
    </>
  );
}

export default Footer