import React, { useState } from 'react'
import { NavLink, Link } from 'react-router-dom'
import './Navbar.css';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import { changeLanguage } from 'i18next';

const Navbar = () => {
  const { t } = useTranslation('navbar');

  const [isOpen, setIsOpen] = useState(false)

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
      <div className="hamburger" onClick={toggleNavbar}>
        <div className={`hamburger-dash ${isOpen ? 'start-dash' : ''}`}></div>
        <div className={`hamburger-dash ${isOpen ? 'middle-dash' : ''}`}></div>
        <div className={`hamburger-dash ${isOpen ? 'end-dash' : ''}`}></div>
      </div>
        <Link to="/">
          <img src="/logo/logo.png" alt="Logo" />
        </Link>
      </div>

      <ul className={`navbar-menu ${isOpen ? 'open' : ''}`}>
        <li>
          <NavLink onClick={toggleNavbar} exact to="/" className="navbar-link" activeClassName="active">{t('home')}</NavLink>
        </li>
        <li>
          <NavLink onClick={toggleNavbar} to="/about" className="navbar-link" activeClassName="active">{t('about')}</NavLink>
        </li>
        <li>
          <NavLink onClick={toggleNavbar} to="/products" className="navbar-link" activeClassName="active">{t('products')}</NavLink>
        </li>
        <li>
          <NavLink onClick={toggleNavbar} to="/gallery" className="navbar-link" activeClassName="active">{t('gallery')}</NavLink>
        </li>
      </ul>

      <div class="toggle-switch">
        <input type="checkbox" id="toggle" class="toggle-input" />
        <label for="toggle" className="toggle-label" onClick={() => {changeLanguage(i18n.language === "en" ? "mr" : "en")}}>
          <span className="toggle-text" id="en-text">EN</span>
          <span className="toggle-text" id="mr-text">MR</span>
        </label>
      </div>

    </nav>
  );
}

export default Navbar