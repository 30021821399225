/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useCallback, useEffect, useState } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import './Home.css';
import Autoplay from 'embla-carousel-autoplay';
import Button from '../components/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const { t } = useTranslation('home');

  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true, draggable: false }, [Autoplay({ delay: 5000, stopOnInteraction: false })])

  const [aboutProperties, setAboutProperties] = useState("about")

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev()
  }, [emblaApi])

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext()
  }, [emblaApi])

  return (
    <>
      <div className="embla" ref={emblaRef}>
        <div className="embla__container">
          <div className="embla__slide">
            <img src="/images/home-carousel-image-1.jpg" alt="" />
            <div className='slide-content'>
              <div className='slide-header'>{t('slide-1-header')}</div>
              <div className='slide-body'>{t('slide-1-body')}</div>
              <div className='slide-footer'>{t('slide-1-footer')}</div>
              <Button text={t('explore')} onClick={() => navigate("/products")} />
            </div>
          </div>
          <div className="embla__slide">
            <img src="/images/home-carousel-image-2.jpg" alt="" />
            <div className='slide-content'>
              <div className='slide-header'>{t('slide-2-header')}</div>
              <div className='slide-body'>{t('slide-2-body')}</div>
              <div className='slide-footer'>{t('slide-2-footer')}</div>
              <Button text={t('explore')} onClick={() => navigate("/products")} />
            </div>
          </div>
          <div className="embla__slide">
            <img src="/images/home-carousel-image-3.jpg" alt="" />
            <div className='slide-content'>
              <div className='slide-header'>{t('slide-3-header')}</div>
              <div className='slide-body'>{t('slide-3-body')}</div>
              <div className='slide-footer'>{t('slide-3-footer')}</div>
              <Button text={t('explore')} onClick={() => navigate("/products")} />
            </div>
          </div>
        </div>

        <div className='scroll__buttons'>
          <button class="embla__prev scroll__button" onClick={scrollPrev}>Prev</button>
          <button class="embla__next scroll__button" onClick={scrollNext}>Next</button>
        </div>
      </div>

      <div id='home-about-container'>
        <div className='home-about'>
          <div className="home-about-image"></div>
          <div className="home-about-content">
            <h5>{t('about-content-header')}</h5>
            <h1>{t('about-content-body')}</h1>
            <h2>{t('about-content-footer')}</h2> 
            <div className='home-about-properties'>
              <span className={"about" === aboutProperties ? "active-property" : ""} onClick={() => setAboutProperties("about")}>{t('about-content-about')}</span>
              <span className={"mission" === aboutProperties ? "active-property" : ""} onClick={() => setAboutProperties("mission")}>{t('about-content-mission')}</span>
              <span onClick={() => setAboutProperties("vision")} className={"vision" === aboutProperties ? "active-property" : ""}>{t('about-content-vision')}</span>
            </div>
            <p className={"about" === aboutProperties ? "active-property-content" : ""}>{t('about-content-about-desc')}</p>
            <p className={"mission" === aboutProperties ? "active-property-content" : ""}>{t('about-content-mission-desc')}</p>
            <p className={"vision" === aboutProperties ? "active-property-content" : ""}>{t('about-content-vision-desc')}</p>
            <div>
              <Button text={t('about-content-button')} onClick={() => navigate("/about")} />
            </div>
          </div>
        </div>
        <div className="about-quality">
          <div className="about-quality-content">
            <div className='about-quality-content-header'>
              <span>{t('about-quality-1')}</span>
              <img src="/images/farming.svg" alt="" />
            </div>
            <div className='about-quality-content-footer'>
              <h2>{t('about-quality-1-header')}</h2>
              <p>{t('about-quality-1-body')}</p>
            </div>
          </div>
          <div className="about-quality-content">
            <div className='about-quality-content-header'>
              <span>{t('about-quality-2')}</span>
              <img src="/images/delivery.svg" alt="" />
            </div>
            <div className='about-quality-content-footer'>
              <h2>{t('about-quality-2-header')}</h2>
              <p>{t('about-quality-2-body')}</p>
            </div>
          </div>
          <div className="about-quality-content">
            <div className='about-quality-content-header'>
              <span>{t('about-quality-3')}</span>
              <img src="/images/agriculture.svg" alt="" />
            </div>
            <div className='about-quality-content-footer'>
              <h2>{t('about-quality-3-header')}</h2>
              <p>{t('about-quality-3-body')}</p>
            </div>
          </div>
          <div className="about-quality-content">
            <div className='about-quality-content-header'>
              <span>{t('about-quality-4')}</span>
              <img src="/images/plant.svg" alt="" />
            </div>
            <div className='about-quality-content-footer'>
              <h2>{t('about-quality-4-header')}</h2>
              <p>{t('about-quality-4-body')}</p>
            </div>
          </div>
        </div>
      </div>
      <div id="home-mid">
        <h2>{t("home-mid-header")}</h2>
        <p>{t("home-mid-content")}</p>
        <Button text={t("home-mid-button")} onClick={() => navigate("/gallery")} />
      </div>

      <div className="home-testimonials-all">
        <div className="home-testimonials-header">
          <h4>{t("testimonial-people")}</h4>
          <h2>{t("testimonials")}</h2>
        </div>
        <div className="home-testimonials-container">
          <div className="home-testimonials">
            <h1>&ldquo;</h1>
            <p>{t("testimonial-1")}</p>
            <div className="testimonials-client">
              <img src="/images/client-1.jpg" alt="" />
              <div>
                <h4>{t("client-name-1")}</h4>
                <span>{t("client")}</span>
              </div>
            </div>
          </div>
          <div className="home-testimonials">
            <h1>&ldquo;</h1>
            <p>{t("testimonial-2")}</p>
            <div className="testimonials-client">
              <img src="/images/client-2.jpg" alt="" />
              <div>
                <h4>{t("client-name-2")}</h4>
                <span>{t("client")}</span>
              </div>
            </div>
          </div>
          <div className="home-testimonials">
            <h1>&ldquo;</h1>
            <p>{t("testimonial-3")}</p>
            <div className="testimonials-client">
              <img src="/images/client-3.jpg" alt="" />
              <div>
                <h4>{t("client-name-3")}</h4>
                <span>{t("client")}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "#33363850", height: "1px", width: '90vw', margin: 'auto' }}></div>

      <div className="home-testimonials-all">
        <div className="home-testimonials-header">
          <h4>{t("sell-header")}</h4>
          <h2>{t("brands")}</h2>
        </div>
        <div className="home-brands">
          <div className="home-brands-1">
            <img src="/images/brand-1.png" alt="" />
            <img src="/images/brand-2.png" alt="" />
            <img src="/images/brand-3.png" alt="" />
            <img src="/images/brand-4.png" alt="" />
            <img src="/images/brand-5.png" alt="" />
          </div>
          <div className="home-brands-2">
            <img src="/images/brand-6.png" alt="" />
            <img src="/images/brand-7.png" alt="" />
            <img src="/images/brand-8.png" alt="" />
            <img src="/images/brand-9.png" alt="" />
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "#33363850", height: "1px", width: '90vw', margin: 'auto' }}></div>

      <div className="home-testimonials-all">
        <div className="home-testimonials-header">
          <h4>{t("location")}</h4>
          <h2>{t("shop-location")}</h2>
        </div>
        <div className="home-map-container">
        <iframe id='home-map' src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3750.995627659788!2d74.72796927522698!3d19.924589081462624!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTnCsDU1JzI4LjUiTiA3NMKwNDMnNTAuMCJF!5e0!3m2!1sen!2sin!4v1732197965074!5m2!1sen!2sin" width="800" height="600" allowfullscreen loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </>
  )
}

export default Home;
