import React, { useEffect, useState } from 'react';
import { BlobServiceClient } from '@azure/storage-blob';
import { CosmosClient } from '@azure/cosmos';
import "./AdminDashboard.css";

const endpoint = process.env.REACT_APP_COSMOS_DB_ENDPOINT;
const key = process.env.REACT_APP_COSMOS_DB_KEY;
const client = new CosmosClient({ endpoint, key });

const databaseId = "ProductsDB";
const containerId = "ProductsCollection";

const sasToken = process.env.REACT_APP_AZURE_SAS_TOKEN;
const storageAccountName = process.env.REACT_APP_STORAGE_ACCOUNT_NAME;

const blobServiceClient = new BlobServiceClient(
  `https://${storageAccountName}.blob.core.windows.net?${sasToken}`
);

async function uploadImage(file, containerName, blobName) {
  if (!file) {
    throw new Error("File is undefined or null");
  }

  const containerClient = blobServiceClient.getContainerClient(containerName);
  const blockBlobClient = containerClient.getBlockBlobClient(blobName);

  try {
    const uploadBlobResponse = await blockBlobClient.uploadBrowserData(file);
    console.log(`Uploaded blob ${blobName} successfully`, uploadBlobResponse.requestId);
    return blockBlobClient.url;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
}


const addProduct = async (product) => {
  const { database } = await client.databases.createIfNotExists({ id: databaseId });
  const { container } = await database.containers.createIfNotExists({ id: containerId });

  const { resource } = await container.items.create(product);
  return resource;
};


const fetchProducts = async () => {
  const { database } = await client.databases.createIfNotExists({ id: databaseId });
  const { container } = await database.containers.createIfNotExists({ id: containerId });

  const { resources } = await container.items.readAll().fetchAll();
  return resources;
};


const AdminDashboard = () => {

  const [file, setFile] = useState(null);

  const [products, setProducts] = useState([]);

  const [formData, setFormData] = useState({
    name: '',
    price: '',
    category: '',
    description: '',
    nameMarathi: '',
    priceMarathi: '',
    descriptionMarathi: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    const imageUrl = await uploadImage(file, process.env.REACT_APP_STORAGE_CONTAINER_NAME, file.name);

    const product = {
      id: Date.now().toString(),
      ...formData,
      imageURL: imageUrl,
      blobName: file.name
    };

    await addProduct(product);
    setProducts((prev) => [...prev, product]);
    alert('Product added successfully!');
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  useEffect(() => {
    const loadProducts = async () => {
      const fetchedProducts = await fetchProducts();
      setProducts(fetchedProducts);
    };

    loadProducts();
  }, []);


  const deleteProductFromDB = async (productId, partitionKey) => {
    const container = client.database(databaseId).container(containerId);

    await container.item(productId, partitionKey).delete();
  };

  const deleteImageFromBlob = async (containerName, blobName) => {

    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);

    await blockBlobClient.delete();
  };

  const handleDelete = async (productId, blobName, partitionKey) => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      try {
        // Delete image from Blob Storage
        await deleteImageFromBlob(process.env.REACT_APP_STORAGE_CONTAINER_NAME, blobName);

        // Delete product from Cosmos DB
        await deleteProductFromDB(productId, partitionKey);

        // Update UI
        setProducts(products.filter((product) => product.id !== productId));

        alert('Product deleted successfully!');
      } catch (error) {
        console.error('Error deleting product:', error);
        alert('Failed to delete product.');
      }
    }
  };

  return (
    <>
      <div className='dashboard-container'>
        <form onSubmit={handleSubmit} className="modern-form">
          <div className="form-group">
            <input type="text" name="name" placeholder="Name (English)" onChange={handleChange} />
            <input type="text" name="nameMarathi" placeholder="Name (Marathi)" onChange={handleChange} />
          </div>
          <div className="form-group">
            <input type="number" name="price" placeholder="Price (English)" onChange={handleChange} />
            <input type="text" name="priceMarathi" placeholder="Price (Marathi)" onChange={handleChange} />
          </div>
          <div className="form-group">
            <input type="text" name="category" placeholder="Category" onChange={handleChange} />
          </div>
          <div className="form-group">
            <textarea name="description" placeholder="Description (English)" onChange={handleChange}></textarea>
            <textarea name="descriptionMarathi" placeholder="Description (Marathi)" onChange={handleChange}></textarea>
          </div>
          <div className="form-group">
            <input type="file" onChange={handleFileChange} />
          </div>
          <button type="submit">Add Product</button>
        </form>


        <div className="delete-product">
          <h2>Delete Products</h2>
          <ul className="product-list">
            {products.map((product) => (
              <li key={product.id} className="product-item">
                <span className="product-details">
                  {product.name} - {product.price} INR
                </span>
                <button
                  className="delete-button"
                  onClick={() => handleDelete(product.id, product.blobName, product.category)}
                >
                  Delete
                </button>
              </li>
            ))}
          </ul>
        </div>

      </div>
    </>
  );
};

export default AdminDashboard;
