import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import homeEN from './translations/en/home.json';
import aboutEN from './translations/en/about.json';
import galleryEN from './translations/en/gallery.json';
import productsEN from './translations/en/products.json';
import navbarEN from './translations/en/navbar.json';
import footerEN from './translations/en/footer.json';

import homeMR from './translations/mr/home.json';
import aboutMR from './translations/mr/about.json';
import galleryMR from './translations/mr/gallery.json';
import productsMR from './translations/mr/products.json';
import navbarMR from './translations/mr/navbar.json'
import footerMR from './translations/mr/footer.json';

i18n.use(initReactI18next).init({
    resources: {
      en: {
        home: homeEN,
        about: aboutEN,
        gallery: galleryEN,
        products: productsEN,
        navbar: navbarEN,
        footer: footerEN
      },
      mr: {
        home: homeMR,
        about: aboutMR,
        gallery: galleryMR,
        products: productsMR,
        navbar: navbarMR,
        footer: footerMR
      }
    },
    lng: 'mr', // default language
    fallbackLng: 'en',
    ns: ['home', 'about', 'gallery', 'product', 'navbar', 'footer'], // namespaces
    defaultNS: 'home', // fallback namespace
    interpolation: {
      escapeValue: false // react handles xss
    }
  });
  
  export default i18n;
