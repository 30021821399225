import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import './Gallery.css';

const Gallery = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation('gallery');

  return (
    <>
      <div id="gallery-head">
        <h2>{t("gallery-head")}</h2>
        <p>{t("gallery-content")}</p>
      </div>

      <div className="gallery-images-box">
        <div className="image-box">
          <img src="https://images.pexels.com/photos/28034954/pexels-photo-28034954/free-photo-of-a-black-and-white-photo-of-a-bridge-over-a-city.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="Gallery" />
        </div>
        <div className="image-box">
          <img src="https://images.pexels.com/photos/26903733/pexels-photo-26903733/free-photo-of-black-and-white-shot-of-a-roller-coaster-in-an-amusement-park.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="Gallery" />
        </div>
        <div className="image-box">
          <img src="https://images.pexels.com/photos/28692482/pexels-photo-28692482/free-photo-of-solitary-chapel-by-the-river-in-tlacotalpan.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="Gallery" />
        </div>
        <div className="image-box">
          <img src="https://images.pexels.com/photos/29565594/pexels-photo-29565594/free-photo-of-surfers-observing-waves-at-taghazout-beach.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="Gallery" />
        </div>
        <div className="image-box">
          <img src="https://images.pexels.com/photos/29462439/pexels-photo-29462439/free-photo-of-ancient-roman-amphitheater-stone-arches.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="Gallery" />
        </div>
        <div className="image-box">
          <img src="https://images.pexels.com/photos/28759039/pexels-photo-28759039/free-photo-of-elegant-bouquet-of-blush-pink-roses.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="Gallery" />
        </div>
        <div className="image-box">
          <img src="https://images.pexels.com/photos/28922850/pexels-photo-28922850/free-photo-of-traditional-japanese-storefront-in-inuyama-japan.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="Gallery" />
        </div>
      </div>
    </>
  )
}

export default Gallery