import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import './About.css';
import Button from '../components/Button';

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation('about');
  const [aboutProperties, setAboutProperties] = useState("about")

  function scrollToView(name) {
    const targetSection = document.getElementById(name);
    const sectionPosition = targetSection.getBoundingClientRect().top + window.scrollY - 107;

    window.scrollTo({
      top: sectionPosition,
      behavior: 'smooth'
    });
  }

  return (<>
    <div id="about-head">
      <h2>{t("about-head-header")}</h2>
      <p>{t("about-head-content")}</p>
    </div>

    <div id='about-main-container'>
      <div className='about-main'>
        <div className="about-main-image"></div>
        <div className="about-main-content">
          <div className='about-main-header'>
            <h1>&ldquo;</h1>
            <h2>{t('about-main-footer')}</h2>
          </div>
          <div className='about-main-properties'>
            <span className={"about" === aboutProperties ? "active-property" : ""} onClick={() => setAboutProperties("about")}>{t('about-content-about')}</span>
            <span className={"mission" === aboutProperties ? "active-property" : ""} onClick={() => setAboutProperties("mission")}>{t('about-content-mission')}</span>
            <span onClick={() => setAboutProperties("vision")} className={"vision" === aboutProperties ? "active-property" : ""}>{t('about-content-vision')}</span>
          </div>
          <p className={"about" === aboutProperties ? "active-property-content" : ""}>{t('about-content-about-desc')}</p>
          <p className={"mission" === aboutProperties ? "active-property-content" : ""}>{t('about-content-mission-desc')}</p>
          <p className={"vision" === aboutProperties ? "active-property-content" : ""}>{t('about-content-vision-desc')}</p>
          <div>
            <Button text={t('about-content-button')} onClick={() => scrollToView("home-mid")} />
          </div>
        </div>
      </div>
    </div>

    <div className='history-container'>
      <h1>{t('about-history-header')}</h1>
      <div className='history-box'>
        <div className='history-box-content'>
          <div className='history-box-content-header'>{t('history-box-1')}</div>
          <p className='history-box-content-text'>{t('history-box-content-1')}</p>
        </div>
        <div className='history-box-content'><div className='history-box-content-header'>{t('history-box-2')}</div>
          <p className='history-box-content-text'>{t('history-box-content-2')}</p></div>
        <div className='history-box-content'><div className='history-box-content-header'>{t('history-box-3')}</div>
          <p className='history-box-content-text'>{t('history-box-content-3')}</p></div>
        <div className='history-box-content'><div className='history-box-content-header'>{t('history-box-4')}</div>
          <p className='history-box-content-text'>{t('history-box-content-4')}</p></div>
      </div>
      <div className='history-timeline-container'>
        <div className="history-timeline-before">
          <div className="timeline-square"></div>
          <div className="timeline-square"></div>
          <div className="timeline-square"></div>
          <div className="timeline-square"></div>
        </div>
        <div className="history-timeline">
        <div className="history-timeline-blocks">
          <div className="timeline-blocks-line" style={{ backgroundColor: "white" }}></div>
          <div className="timeline-blocks-content">{t('time-1')}</div>
          <div className="timeline-blocks-line"></div>
        </div>
        <div className="history-timeline-blocks">
          <div className="timeline-blocks-line"></div>
          <div className="timeline-blocks-content">{t('time-2')}</div>
          <div className="timeline-blocks-line"></div>
        </div>
        <div className="history-timeline-blocks">
          <div className="timeline-blocks-line"></div>
          <div className="timeline-blocks-content">{t('time-3')}</div>
          <div className="timeline-blocks-line"></div>
        </div>
        <div className="history-timeline-blocks">
          <div className="timeline-blocks-line"></div>
          <div className="timeline-blocks-content">{t('time-4')}</div>
          <div className="timeline-blocks-line" style={{ backgroundColor: "white" }}></div>
        </div>
        </div>
      </div>
    </div>
    <div className="owner-container">
      <h1 className="owner-header">{t("owner-header")}</h1>
      <img className="owner-image" src="/images/ownerImage.jpg" alt='ownerImage'></img>
      <div className="owner-name">{t("owner-name")}</div>
      <div className="owner-info">{t("owner-info")}</div>
    </div>
    <div className="shop-stats-container">
      <div className="shop-stats-block">
        <div className="stats-head">{t("stats-n-1")}</div>
        <div className="stats-line"></div>
        <p className="stats-name">{t("stats-name-1")}</p>
      </div>
      <div className="shop-stats-block">
        <div className="stats-head">{t("stats-n-2")}</div>
        <div className="stats-line"></div>
        <p className="stats-name">{t("stats-name-2")}</p>
      </div>
      <div className="shop-stats-block">
        <div className="stats-head">{t("stats-n-3")}</div>
        <div className="stats-line"></div>
        <p className="stats-name">{t("stats-name-3")}</p>
      </div>
      <div className="shop-stats-block">
        <div className="stats-head">{t("stats-n-4")}</div>
        <div className="stats-line"></div>
        <p className="stats-name">{t("stats-name-4")}</p>
      </div>
    </div>
  </>
  )
}

export default About