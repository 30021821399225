import React, { useEffect, useState } from 'react';
import './Products.css';
import ProductCard from '../components/ProductCard';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { CosmosClient } from '@azure/cosmos';
import ProductPop from "../components/ProductPop";

const endpoint = process.env.REACT_APP_COSMOS_DB_ENDPOINT;
const key = process.env.REACT_APP_COSMOS_DB_KEY;
const client = new CosmosClient({ endpoint, key });

const databaseId = process.env.REACT_APP_DATABASEID;
const containerId = process.env.REACT_APP_CONTAINERID;

const fetchProducts = async () => {
  const { database } = await client.databases.createIfNotExists({ id: databaseId });
  const { container } = await database.containers.createIfNotExists({ id: containerId });

  const { resources } = await container.items.readAll().fetchAll();
  return resources;
};

const Products = () => {
  const { t } = useTranslation('products');

  const [products, setProducts] = useState([]);

  const [filteredProducts, setFilteredProducts] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState('all');

  const [searchQuery, setSearchQuery] = useState('');

  const [selectedProduct, setSelectedProduct] = useState(null);

  const [language, setLanguage] = useState(i18next.language);

  useEffect(() => {
    window.scrollTo(0, 0);

    const loadProducts = async () => {
      const fetchedProducts = await fetchProducts();
      setProducts(fetchedProducts);
      setFilteredProducts(fetchedProducts);
    };

    loadProducts();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  

  useEffect(() => {
    i18next.on('languageChanged', ()=>{setLanguage(language === "en"? "mr" : "en")});

    return () => {
      i18next.off('languageChanged', ()=>{setLanguage(language === "en"? "mr" : "en")});
    };
  }, [language]);

  useEffect(() => {
    const filtered = products.filter((product) => {
      const name = product.name.toLowerCase();
      const matchesSearch = name.includes(searchQuery.toLowerCase());
      const matchesCategory = selectedCategory === 'all' || product.category === selectedCategory;

      return matchesSearch && matchesCategory;
    });

    setFilteredProducts(filtered);
  }, [searchQuery, selectedCategory, products]);


  const handleProductClick = (product) => {
    setSelectedProduct(product);
  };



  const handleCloseModal = () => {
    setSelectedProduct(null);
  };

  return (
    <>
      <div id="products-head">
        <h2>{t("products-head-header")}</h2>
        <p>{t("products-head-content")}</p>
      </div>

      <div className="filter-container">
      <input
        type="text"
        placeholder={t("search")}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="search-bar"
      />
      <select
        value={selectedCategory}
        onChange={(e) => setSelectedCategory(e.target.value)}
        className="category-filter"
      >
        <option value="all">{t("all")}</option>
        <option value="pesticides">{t("pesticides")}</option>
        <option value="fertilizers">{t("fertilizers")}</option>
        <option value="plant-protection">{t("plant-protection")} </option>
      </select>
    </div>

      <div>
      {filteredProducts.length > 0 ? (
        <div className="product-list">
          {filteredProducts.map((product) => (
            <ProductCard imageUrl={product.imageURL} name={language === "en" ? product.name: product.nameMarathi} price={language === "en" ? product.price: product.priceMarathi} onClick={() => handleProductClick(product)}/>
          ))}
        </div>
      ) : (
        <p className='no-products'>No products found.</p>
      )}
    </div>

    <ProductPop product={selectedProduct} onClose={handleCloseModal} />
    </>
  );
};

export default Products;
