import './ProductCard.css';

const ProductCard = ({ imageUrl, name, price, onClick }) => {
    return (
        <div className="card" onClick={onClick}>
            <div>
                <img className="card-img" src={imageUrl} alt={name} />
            </div>
            <div className="card-info">
                <p className="text-title">{name}</p>
            </div>
            <div className="card-footer">
                <span className="text-price">&#8377; {price}</span>
            </div>
        </div>
    );
}

export default ProductCard;
